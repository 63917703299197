<template>
    <Page title="Webhooks" icon="mdi-application-import">
        <smart-form v-model="formModel" :schema="queryFormSchema" form-code="webhook-filter" />
        <smart-table
            :headers="headers"
            :items="items"
            :key="items.length"
            @click:row="loadRestOfData"
        >
            <template v-slot:item.data="{ item, on }">
                <v-dialog v-model="item.dataDialog" max-width="600px">
                    <template v-slot:activator="{ on }">
                        <btn v-on="loadRestOfData(item, on)" iconCode="mdi-code-braces" fab small />
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Data</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm6 md4>
                                        <pre>{{ JSON.stringify(data, null, 2) }}</pre>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
        </smart-table>
    </Page>
</template>
<script>
import objectUtil from '@/../common/utils/object';
import api from '@/api';

const entities = ['Account', 'Bill', 'Class', 'Customer', 'Invoice', 'Item', 'Vendor'];

const queryFormSchema = {
    selectDate: {
        component: 'date-field',
        label: 'Received',
        cols: 6,
        clearable: true,
        today: true
    },
    entity: {
        component: 'select-field',
        label: 'Accounts Entity',
        cols: 4,
        items: entities,
    },
    accountsCompanyId: {
        component: 'text-field',
        autocomplete: {
            type: 'company',
        },
        label: 'Accounts Company',
        cols: 4,
        clearable: true,
    },
};
const headers = [
    {
        text: 'Company Name',
        align: 'center',
        sortable: false,
        value: 'companyName',
    },
    {
        text: 'Entity',
        align: 'center',
        sortable: false,
        value: 'entityName',
    },
    {
        text: 'Id',
        align: 'center',
        sortable: false,
        value: 'entityId',
    },
    {
        text: 'Operation',
        align: 'center',
        sortable: false,
        value: 'operation',
    },
    {
        text: 'Date / Time',
        align: 'center',
        sortable: false,
        value: 'date',
        customComponent: 'DateTime',
        hideTime: false,

    },
    {
        text: '',
        align: 'center',
        sortable: false,
        value: 'data',
    },
];
export default {
    name: 'Webhook',
    components: {},
    data() {
        return {
            accountsCompanyId: null,
            formModel: { accountsCompanyId: undefined, selectDate: '' },
            queryFormSchema,
            headers,
            fromDate: null,
            toDate: null,
            items: [],
            companies: [],
            data: {},
        };
    },

    methods: {
        loadRestOfData(item, on) {
            return {
                click: e => {
                    if (item) {
                        api.post(this.$store, `qb/webhook/entity`, item)
                            .then(response => {
                                this.data = response.data;
                                on.click({ stopPropagation: () => {} });
                            })
                            .catch(() => {
                                this.$store.dispatch('setError', 'Could not load log item data');
                            });
                    }
                },
            };
        },
        async loadWebhooks() {
            const url = 'qb/webhook/search';
            this.fromDate = this.formModel.selectDate;
            if (this.fromDate !== '') {
                let filter = {};
                if (this.formModel.accountsCompanyId && this.formModel.accountsCompanyId !== '')
                    filter = { ...filter, accountsCompanyId: this.formModel.accountsCompanyId };
                if (this.fromDate && this.fromDate !== '')
                    filter = {
                        ...filter,
                        fromDate: this.fromDate,
                    };
                if (this.toDate && this.toDate !== '') filter = { ...filter, toDate: this.toDate };
                if (this.formModel.entity && this.formModel.entity !== '')
                    filter = { ...filter, entityName: this.formModel.entity };

                const webhooksResult = await api.post(this.$store, `${url}`, filter);
                this.items = webhooksResult.data ? webhooksResult.data : [];
            }
        },
    },
    watch: {
        'formModel.selectDate'() {
            this.loadWebhooks();
        },
        'formModel.accountsCompanyId'() {
            this.loadWebhooks();
        },
        'formModel.entity'() {
            this.loadWebhooks();
        },
    },
    async mounted() {
        this.formSchema = objectUtil.setObjectFieldByString(
            'selectDate.on.change',
            this.formSchema,
            this.loadWebhooks,
        );

        await this.$store.dispatch('setLoading', 'Getting Webhooks');

        await this.loadWebhooks();
        //this.logItems = [...this.logItems, ...loadedItems];

        await this.$store.dispatch('setLoading');
    },
};
</script>
